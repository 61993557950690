<template>
    <div class="Admin">
        <main-header menuActive="dashboard" />

        <div id="contentsWrap">
            <div class="container sub">
                <div class="row">
                    <div class="subHead">
                        <h3># 총괄 현황</h3>
                    </div>
                    <table class="table table-profile">
                        <tbody>
                        <tr>
                            <th class="field text-center font-weight-bold" colspan="6">총계</th>
                        </tr>
                        <tr>
                            <td class="text-center" colspan="6">
                                {{ dataList.totalCnt | comma }} 명
                                ( 당일 : {{ dataList.todayCount | comma }} 명)
                            </td>
                        </tr>
                        <tr>
                            <th class="field text-center font-weight-bold" colspan="3" style="border-right:2px solid #000000">확진자</th>
                            <th class="field text-center font-weight-bold" colspan="3">시설별 배정</th>
                        </tr>
                        <tr>
                            <th class="field text-center font-weight-bold" colspan="2">미배정</th>
                            <th class="field text-center font-weight-bold" rowspan="2" style="border-right:2px solid #000000">배정완료</th>
                            <!--                        <th class="field text-center font-weight-bold" rowspan="2">퇴소/퇴원</th>-->
                            <th class="field text-center font-weight-bold" rowspan="2">병원</th>
                            <th class="field text-center font-weight-bold" rowspan="2">생치</th>
                            <th class="field text-center font-weight-bold" rowspan="2">구생치</th>
                        </tr>
                        <tr>
                            <th class="field text-center font-weight-bold">병원</th>
                            <th class="field text-center font-weight-bold">생치</th>
                        </tr>
                        <tr>
                            <td class="text-center">{{ dataList.nonAssignmentList.hospCnt | comma }} 명</td>
                            <td class="text-center">{{ dataList.nonAssignmentList.centerCnt | comma }} 명</td>
                            <td class="text-center" style="border-right:2px solid #000000">{{ dataList.assignmentCnt | comma }} 명</td>
                            <!--                        <td class="text-center">{{ dataList.list_total_status.exit_cnt | comma }} 명</td>-->
                            <td class="text-center">{{ dataList.facilityAssignmentList.hospCnt | comma }} 명</td>
                            <td class="text-center">{{ dataList.facilityAssignmentList.newCenterCnt | comma }} 명</td>
                            <td class="text-center">{{ dataList.facilityAssignmentList.oldCenterCnt | comma }} 명</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row">

                    <div class="subHead">
                        <h3># 당일 현황</h3>
                      <div class="pull-right" style="padding:10px 0 10px 0;font-weight:bold">* 접수일 기준</div>
                    </div>
                    <table class="table table-profile">
                        <tbody>
                        <tr>
                            <th class="field text-center font-weight-bold"  style="border-right:2px solid #000000">당일 확진자</th>
                            <th class="field text-center font-weight-bold" colspan="4" style="border-right:2px solid #000000">배정완료</th>
                            <th class="field text-center font-weight-bold" colspan="3">미배정</th>
                        </tr>
                        <tr>
                            <td class="text-center" rowspan="2" style="border-right:2px solid #000000">{{ dataList.todayCount | comma }} 명</td>
                            <th class="field text-center font-weight-bold">합계</th>
                            <th class="field text-center font-weight-bold">병원</th>
                            <th class="field text-center font-weight-bold">생치</th>
                            <th class="field text-center font-weight-bold"  style="border-right:2px solid #000000">구생치</th>

                          <th class="field text-center font-weight-bold">합계</th>
                            <th class="field text-center font-weight-bold">병원</th>
                            <th class="field text-center font-weight-bold">생치</th>
                        </tr>
                        <tr>
                            <td class="text-center">{{ dataList.todayAssignmentList.totCnt | comma }} 명</td>
                            <td class="text-center">{{ dataList.todayAssignmentList.hospCnt | comma }} 명</td>
                            <td class="text-center">{{ dataList.todayAssignmentList.newCenterCnt | comma }} 명</td>
                            <td class="text-center" style="border-right:2px solid #000000">{{ dataList.todayAssignmentList.oldCenterCnt | comma }} 명</td>

                            <td class="text-center">{{ dataList.todayNonAssignmentList.totCnt | comma }} 명</td>
                            <td class="text-center">{{ dataList.todayNonAssignmentList.hospCnt | comma }} 명</td>
                            <td class="text-center">{{ dataList.todayNonAssignmentList.centerCnt | comma }} 명</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="row">

                  <div class="subHead">
                    <h3># 일별 미배정 현황</h3>
                    <div class="pull-right" style="padding:10px 0 10px 0;font-weight:bold">* 접수일 기준</div>
                  </div>
                  <table class="table table-profile">
                    <tbody>
                    <tr>
                      <th class="field text-center font-weight-bold">날짜</th>
                      <th class="field text-center font-weight-bold">계</th>
                      <th class="field text-center font-weight-bold">생치</th>
                      <th class="field text-center font-weight-bold">병원</th>
                      <!--<th class="field text-center font-weight-bold">재택치료</th>-->
                      <th class="field text-center font-weight-bold">구생치</th>
                    </tr>
                    <tr>
                      <td class="text-center">{{ dataList.dayNonAssignmentList.fregDate }}</td>
                      <td class="text-center">{{ dataList.dayNonAssignmentList.totCnt | comma }} 명</td>
                      <td class="text-center">{{ dataList.dayNonAssignmentList.newCenterCnt | comma }} 명</td>
                      <td class="text-center">{{ dataList.dayNonAssignmentList.hospCnt | comma }} 명</td>
                     <!--<td class="text-center">{{ data.G0206 | comma }} 명</td>-->
                      <td class="text-center">{{ dataList.dayNonAssignmentList.oldCenterCnt | comma }} 명</td>
                    </tr>
                    </tbody>
                  </table>
                </div>

                <div class="row searchBtn">
                    <div class="pull-right">
                        <a class="btn8" @click="excelDownFunc" style="color:#fff">엑셀다운로드</a>
                    </div>
                </div>
            </div>
        </div>
        <main-footer />
    </div>
</template>

<script>
import mainHeader from '@/components/MainHeader.vue';
import mainFooter from '@/components/MainFooter.vue';

export default {
    name: 'dashboardIndex',
  data() {
    return {
      dataList: {
        "assignmentCnt": 0,
        "totalCnt": 0,
        "dayNonAssignmentList": {
          "hospCnt": 0,
          "oldCenterCnt": 0,
          "newCenterCnt": 0,
          "totCnt": 0,
          "fregDate": "총계"
        },
        "code": 200,
        "success": true,
        "nonAssignmentList": {
          "centerCnt": 0,
          "hospCnt": 0,
          "oldCenterCnt": 0,
          "newCenterCnt": 0,
          "totCnt": 0,
          "fregDate": null
        },
        "facilityAssignmentList": {
          "centerCnt": 0,
          "hospCnt": 0,
          "oldCenterCnt": 0,
          "newCenterCnt": 0,
          "totCnt": 0,
          "fregDate": null
        },
        "todayCount": 0,
        "todayAssignmentList": {
          "centerCnt": 0,
          "hospCnt": 0,
          "oldCenterCnt": 0,
          "newCenterCnt": 0,
          "totCnt": 0,
          "fregDate": null
        },
        "message": "성공",
        "todayNonAssignmentList": {
          "centerCnt": 0,
          "hospCnt": 0,
          "oldCenterCnt": 0,
          "newCenterCnt": 0,
          "totCnt": 0,
          "fregDate": null
        }
      }
    }
  },
  components: { mainHeader, mainFooter },
    // 계산된 속성
    computed: {
    },
    // 감시자
    watch: {

    },
    // $el 생성전
    created() {
        this.checkLogin();

    },
    // $el 생성후
    mounted() {
        this.searchListFunc()
    },
    methods: {
        // 리스트 검색
        searchListFunc(){
            let params = "";
            // formData.append('api_name', 'dashboard');                    // API 이름
            // formData.append('f_admin_id', this.userDataAdminId);

            this.ajaxGet('nocDashboard/list?params=', params,'searchListFuncResult');
        },
        searchListFuncResult(response) {
            this.dataList = response;
            console.log(this.dataList);

            if (this.dataList.dayNonAssignmentList.length === 0) {
                this.dataList.dayNonAssignmentList = {
                    "hospCnt": 0,
                    "oldCenterCnt": 0,
                    "newCenterCnt": 0,
                    "totCnt": 0,
                    "fregDate": "총계"
                }
            }
            // this.dataList = result.list_data;

            // let totalHospitalStatusSum = 0
            // result.list_data.list_hospital_status.forEach(function (item) {
            //   totalHospitalStatusSum = parseInt(item.cnt) +  totalHospitalStatusSum;
            // });
            // this.totalHospitalStatus = totalHospitalStatusSum;

            // let totalCenterStatusSum = 0
            // result.list_data.list_center_status.forEach(function (item) {
            //     totalCenterStatusSum = parseInt(item.cnt) +  totalCenterStatusSum;
            // });
            // this.totalCenterStatus = totalCenterStatusSum;

            // let totalRegionAssignStatusTodayCnt = 0
            // let totalRegionAssignStatusAssignCnt = 0
            // let totalRegionAssignStatusUnassignCnt = 0
            // let totalRegionAssignStatusExitCnt = 0
            // let totalRegionAssignStatusHospCnt = 0
            // let totalRegionAssignStatusCityCnt = 0
            // let totalRegionAssignStatusRegionCnt = 0
            //
            // result.list_data.list_region_assign_status.forEach(function (item) {
            //     totalRegionAssignStatusTodayCnt = parseInt(item.today_cnt) +  totalRegionAssignStatusTodayCnt;
            //     totalRegionAssignStatusAssignCnt = parseInt(item.assign_cnt) +  totalRegionAssignStatusAssignCnt;
            //     totalRegionAssignStatusUnassignCnt = parseInt(item.unassign_cnt) +  totalRegionAssignStatusUnassignCnt;
            //     totalRegionAssignStatusExitCnt = parseInt(item.exit_cnt) +  totalRegionAssignStatusExitCnt;
            //     totalRegionAssignStatusHospCnt = parseInt(item.hosp_cnt) +  totalRegionAssignStatusHospCnt;
            //     totalRegionAssignStatusCityCnt = parseInt(item.city_cnt) +  totalRegionAssignStatusCityCnt;
            //     totalRegionAssignStatusRegionCnt = parseInt(item.region_cnt) +  totalRegionAssignStatusRegionCnt;
            // });
            //
            // this.totalRegionAssignStatusTodayCnt = totalRegionAssignStatusTodayCnt;
            // this.totalRegionAssignStatusAssignCnt = totalRegionAssignStatusAssignCnt;
            // this.totalRegionAssignStatusUnassignCnt = totalRegionAssignStatusUnassignCnt;
            // this.totalRegionAssignStatusExitCnt = totalRegionAssignStatusExitCnt;
            // this.totalRegionAssignStatusHospCnt = totalRegionAssignStatusHospCnt;
            // this.totalRegionAssignStatusCityCnt = totalRegionAssignStatusCityCnt;
            // this.totalRegionAssignStatusRegionCnt = totalRegionAssignStatusRegionCnt;

            // let totalRegionUnassignStatusD1Sum = 0
            // let totalRegionUnassignStatusD2Sum = 0
            // let totalRegionUnassignStatusD3Sum = 0
            // let totalRegionUnassignStatusD4Sum = 0
            // let totalRegionUnassignStatusD5Sum = 0
            // let totalRegionUnassignStatusD6Sum = 0
            // let totalRegionUnassignStatusD7Sum = 0
            //
            // result.list_data.list_region_unassign_status.forEach(function (item) {
            //     totalRegionUnassignStatusD1Sum = parseInt(item.D01) +  totalRegionUnassignStatusD1Sum;
            //     totalRegionUnassignStatusD2Sum = parseInt(item.D02) +  totalRegionUnassignStatusD2Sum;
            //     totalRegionUnassignStatusD3Sum = parseInt(item.D03) +  totalRegionUnassignStatusD3Sum;
            //     totalRegionUnassignStatusD4Sum = parseInt(item.D04) +  totalRegionUnassignStatusD4Sum;
            //     totalRegionUnassignStatusD5Sum = parseInt(item.D05) +  totalRegionUnassignStatusD5Sum;
            //     totalRegionUnassignStatusD6Sum = parseInt(item.D06) +  totalRegionUnassignStatusD6Sum;
            //     totalRegionUnassignStatusD7Sum = parseInt(item.D07) +  totalRegionUnassignStatusD7Sum;
            // });
            // this.totalRegionUnassignStatusD1 = totalRegionUnassignStatusD1Sum;
            // this.totalRegionUnassignStatusD2 = totalRegionUnassignStatusD2Sum;
            // this.totalRegionUnassignStatusD3 = totalRegionUnassignStatusD3Sum;
            // this.totalRegionUnassignStatusD4 = totalRegionUnassignStatusD4Sum;
            // this.totalRegionUnassignStatusD5 = totalRegionUnassignStatusD5Sum;
            // this.totalRegionUnassignStatusD6 = totalRegionUnassignStatusD6Sum;
            // this.totalRegionUnassignStatusD7 = totalRegionUnassignStatusD7Sum;
        },
        excelDownFunc(){
            console.log("엑셀다운로드");
            /*
            let url=this.rootUrl+'/_excel/excel_dashboard.php';
            this.excelExport(url);
            */
            let params = "";

            this.ajaxGet('nocDashboard/list?params=', params,'downloadExcel');
        },
        downloadExcel(response) {
            if (!response || !response.nonAssignmentList || response.nonAssignmentList.length === 0) {
                console.error('Empty or undefined dataList in response');
                return;
            }

            let dataList = response.nonAssignmentList; // 데이터 가져오기 (확진자 미배정)
            let dataList2 = response.facilityAssignmentList; // 데이터 가져오기 (시설별 배정완료)
            let dataList3 = response.todayAssignmentList; // 데이터 가져오기 (당일 배정)
            let dataList4 = response.todayNonAssignmentList; // 데이터 가져오기 (당일접수 미배정)
            let dataList5 = response.dayNonAssignmentList; // 데이터 가져오기 (일별 미배정 현황)

            let assignmentCnts = response.assignmentCnt; // 데이터 가져오기  (확진자 배정현황 (배정완료))
            let totalCnts = response.totalCnt; // 데이터 가져오기 (총계현황 총계)
            let todayCounts = response.todayCount; // 데이터 가져오기 (당일확진자)

            console.log(dataList);
            console.log(dataList2);
            console.log(dataList3);
            console.log(dataList4);
            console.log(dataList5);

            console.log(assignmentCnts);
            console.log(totalCnts);
            console.log(todayCounts);

            let excelData = [];

            // 엑셀 1열 (a~f행 병합)
            excelData.push(['총계']);

            let totals = totalCnts + "명 (당일 : " + todayCounts + "명) "
           
            // 엑셀 2열 (데이터 총계 a~f행 병합)
            excelData.push([totals]);

            // 엑셀 3열 (확진자 a~c행 병합, 시설별 배정 d~f행 병합)
            excelData.push(['확진자', '', '', '시설별 배정']);

            // 엑셀 4열 (미배정 병원, 미배정 생치, 배정완료, 병원, 생치, 구생치)
            excelData.push(['미배정 병원', '미배정 생치', '배정완료', '병원', '생치', '구생치']);

            // 엑셀 5열 데이터 삽입
            excelData.push([dataList.centerCnt + '명', dataList.hospCnt + '명', assignmentCnts + '명', dataList2.hospCnt + '명', dataList2.newCenterCnt + '명' , dataList2.oldCenterCnt + '명' ]);

            // 엑셀 6열 (빈값)
            excelData.push([]);

            // 엑셀 7열 (당일확진자, 배정완료 b~e행 병합, 미배정 f~h행 병합)
            excelData.push(['당일확진자', '배정완료', '', '', '', '미배정']);

            // 엑셀 8열 ('', 합계, 병원, 생치, 구생치, 합계, 병원, 생치)
            excelData.push(['', '합계', '병원', '생치', '구생치', '합계', '병원', '생치']);

            // 엑셀 9열 데이터 삽입
            excelData.push([todayCounts + "명", 
            dataList3.totCnt + "명", dataList3.hospCnt + "명", dataList3.newCenterCnt + "명", dataList3.oldCenterCnt + "명", 
            dataList4.totCnt + "명", dataList4.hospCnt + "명", dataList4.centerCnt + "명" ]);

            // 엑셀 10열 빈값 
            excelData.push([]);

            // 엑셀 11열 (날짜, 계, 생치, 병원, 구생치)
            excelData.push(['날짜', '계', '생치', '병원', '구생치']);

            if (!dataList5 || dataList5.length === 0) {
                // 엑셀 11열의 총계
                excelData.push(['총계', 0 + '명', 0 + '명', 0 + '명', 0 + '명']);
            } else {
                let totalNewCenterCnt = 0;
                let totalHospCnt = 0;
                let totalOldCenterCnt = 0;
                let totalSum = 0;

                // 엑셀 12열 
                dataList5.forEach((data, index) => {
                    excelData.push([
                        data.fregDate, 
                        data.totCnt, 
                        data.newCenterCnt, 
                        data.hospCnt, 
                        data.oldCenterCnt 
                    ]);

                    //  각 데이터를 총계에 누적
                    totalNewCenterCnt += data.newCenterCnt;
                    totalHospCnt += data.hospCnt;
                    totalOldCenterCnt += data.oldCenterCnt;
                    totalSum += data.totCnt;
                });

                // 엑셀 13열 (총계)
                excelData.push(['총계', totalSum + '명', totalNewCenterCnt + '명', totalHospCnt + '명', totalOldCenterCnt + '명']);
            }
     
            console.log(excelData);

            // 엑셀 라이브러리 가져오기
            const XLSX = require('xlsx');

            // 엑셀 파일 생성
            let ws = XLSX.utils.aoa_to_sheet(excelData);

            // 셀 병합
            ws['!merges'] = [
                { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } }, // 1열의 셀을 병합 (a~f)
                { s: { r: 1, c: 0 }, e: { r: 1, c: 5 } }, // 2열의 셀을 병합 (a~f)
                { s: { r: 2, c: 0 }, e: { r: 2, c: 2 } }, // 3열의 셀을 병합 (a~c)
                { s: { r: 2, c: 3 }, e: { r: 2, c: 5 } }, // 3열의 셀을 병합 (f~h)

                { s: { r: 6, c: 0 }, e: { r: 7, c: 0 } }, // 7열의 셀을 병합 (당일 확진자 셀 병합)

                { s: { r: 6, c: 1 }, e: { r: 6, c: 4 } }, // 7열의 셀을 병합 (b~e행)
                { s: { r: 6, c: 5 }, e: { r: 6, c: 7 } }, // 7열의 셀을 병합 (f~h행)
            ];

            

            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

            // 엑셀 파일 다운로드
            XLSX.writeFile(wb, '병상배정_대시보드.xlsx');
        },
        searchFasilityFunc(code){
          let data={};
          data.sort_key = code;

          this.$router.push({ name: 'AdminFacilityList', params: {data} })
        },
    }
}
</script>

<style scoped>
</style>
